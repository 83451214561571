import { listNavDetauls, listNavEnter } from "@/api";
export default {
  data() {
    return {
      show: false,
      password: "",
      title: "",
      amount: "",
      info: "",
      imgs: [require("../../assets/projects/class1.png"), require("../../assets/projects/class2.png"), require("../../assets/projects/class3.png")]
    };
  },
  mounted() {
    let id = this.$route.query.id;
    // if (id < 5) {
    //   this.imgs = require(`@/assets/future/future_${id}_bg.png`)
    // } else {
    //   this.imgs = require(`@/assets/future/future_1_bg.png`)

    // }
    this.title = this.$route.query.title;
    listNavDetauls(id).then(res => {
      this.info = res.data;
    });
  },
  methods: {
    toDOM(value) {
      const parser = new DOMParser();
      let dom = parser.parseFromString(value, 'text/html').documentElement.textContent;
      return dom;
    },
    openPop() {
      this.show = true;
    },
    closePop() {
      this.show = false;
    },
    submit(info) {
      if (this.password.length < 3) {
        this.$closeToast();
        this.$toast("密码错误");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      let amount;
      if (this.headIndex === 3) {
        amount = parseFloat(this.info.icon);
      } else {
        amount = parseFloat(this.info.min);
      }
      listNavEnter({
        item_id: info.id,
        pwd: this.password,
        amount: amount
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.show = false;
        this.$store.dispatch('getUserInfo');
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    headIndex() {
      return this.$store.state.headIndex;
    }
  }
};